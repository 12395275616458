// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.animated {
    animation-duration: 0.5;
}

/* animate page transitions */

.au-enter-active {
    animation: fadeIn 0.5 ease-out;
}

.au-leave-active {
    animation: fadeOut 0.5 ease-out;
}

.au-animate.aurelia-hide-add {
    animation: fadeOut 0.5 ease-out;
}

.au-animate.aurelia-hide-remove {
    animation: fadeIn 0.5 ease-out;
}

.au-animate.aurelia-hide-remove.swap-delay {
    animation-delay: 0.5;
    opacity: 0;
}

.background-animation-add {
    animation: fadeOut 0.5 ease-out;
}

.background-animation-remove {
    animation: fadeIn 0.5 ease-out;
}`, "",{"version":3,"sources":["webpack://./styles/animations.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA,6BAA6B;;AAE7B;IACI,8BAA8B;AAClC;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,oBAAoB;IACpB,UAAU;AACd;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,8BAA8B;AAClC","sourcesContent":[".animated {\r\n    animation-duration: 0.5;\r\n}\r\n\r\n/* animate page transitions */\r\n\r\n.au-enter-active {\r\n    animation: fadeIn 0.5 ease-out;\r\n}\r\n\r\n.au-leave-active {\r\n    animation: fadeOut 0.5 ease-out;\r\n}\r\n\r\n.au-animate.aurelia-hide-add {\r\n    animation: fadeOut 0.5 ease-out;\r\n}\r\n\r\n.au-animate.aurelia-hide-remove {\r\n    animation: fadeIn 0.5 ease-out;\r\n}\r\n\r\n.au-animate.aurelia-hide-remove.swap-delay {\r\n    animation-delay: 0.5;\r\n    opacity: 0;\r\n}\r\n\r\n.background-animation-add {\r\n    animation: fadeOut 0.5 ease-out;\r\n}\r\n\r\n.background-animation-remove {\r\n    animation: fadeIn 0.5 ease-out;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
